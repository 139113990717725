<template>
<div class="">
    <!-- Footer -->
<!-- <div class="page-footer bg-light">
  <b-container>
    <b-row>
      <b-col lg="4" class="footer-about mb-2 mb-lg-0">
        <img src="@/assets/images/logo/logo.png" alt="cloud360s">
        <p>Donut jujubes I love topping I love sweet. Jujubes I love brownie gummi bears I love donut sweet chocolate. Tart chocolate marshmallow.Tart carrot cake muffin.</p>
        <p>Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies. Cupcake cake I love cake danish carrot cake.</p>
      </b-col>

      <b-col lg="2" class="quick-links mb-2 mb-lg-0">
        <h4>Quick Links</h4>
        <ul class="list-unstyled">
          <li class=""><b-link href="#">Home</b-link></li>
          <li><b-link href="#">About us</b-link></li>
          <li><b-link href="#">Events</b-link></li>
          <li><b-link href="#">Products</b-link></li>
          <li><b-link href="#">Contact us</b-link></li>
          <li><b-link href="#">Privacy policy</b-link></li>
          <li><b-link href="#">Sitemap</b-link></li>
        </ul>
      </b-col>

      <b-col lg="2" class="quick-links">
        <h4>Quick Links</h4>
        <ul class="list-unstyled">
          <li class=""><b-link href="#">Home</b-link></li>
          <li><b-link href="#">About us</b-link></li>
          <li><b-link href="#">Events</b-link></li>
          <li><b-link href="#">Products</b-link></li>
          <li><b-link href="#">Contact us</b-link></li>
          <li><b-link href="#">Privacy policy</b-link></li>
          <li><b-link href="#">Sitemap</b-link></li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</div> -->
<footer class="copyright-footer footer">
  <b-container>
    <b-row>
      <b-col lg="6">
      <span>2024 © <span>BRIKS Technology</span>. All Rights Reserved.</span> | 
      <span>
        <a href="https://briks.ai/privacy-policy" target="_blank">Privacy Policy</a>
      </span> | 
      <span>
        <a href="https://briks.ai/termsandconditions" target="_blank">T&C</a>
      </span> |
      <span>
        For Developers: <a href="https://doc.briks.ai/" target="_blank">API Documentation</a>
      </span>
    </b-col>
    <b-col lg="6" class="text-right">
      <span>
        
        Contact Us For Partnerships/Enquiry:
        <a href="mailto:hello@briks.ai" target="_blank">hello@briks.ai</a>
      </span> |   
      <a href="https://www.linkedin.com/in/kirandass/" target="_blank" class="">
        <b-icon-linkedin font-scale="1"></b-icon-linkedin>
      </a> | 
      <a href="https://x.com/hello_krn" target="_blank" class="">
        <b-icon-twitter font-scale="1"></b-icon-twitter>
      </a>
    </b-col>
    </b-row>
  </b-container>
  </footer>
<!--End Footer -->

</div>
</template>

<script>
import { BIcon, BIconShieldLock, BIconFileCode, BIconEnvelope, BIconLinkedin, BIconTwitter, BCard, BCardText, BButton, BContainer, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BBadge, BLink} from 'bootstrap-vue'

export default {
  components: {
    BIcon,
    BIconShieldLock,
    BIconFileCode,
    BIconEnvelope,
    BIconLinkedin,
    BIconTwitter,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BBadge,
    BLink,
  },
}
</script>

<style scoped>
body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
footer.footer {
  padding:24px 0;
}
</style>



