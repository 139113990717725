import { render, staticRenderFns } from "./BottomNavbar.vue?vue&type=template&id=ee7f7c36"
import script from "./BottomNavbar.vue?vue&type=script&lang=js"
export * from "./BottomNavbar.vue?vue&type=script&lang=js"
import style0 from "./BottomNavbar.vue?vue&type=style&index=0&id=ee7f7c36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports