<template>
<div class="">
    <div class='main-loader' v-if='isLoading'></div>
    <div class="c-navbar">
        <b-navbar class="header-navbar navbar-shadow" toggleable="lg" type="light" variant="faded">
            <div class="navbar-container d-flex align-items-center">
                <b-navbar-brand to="/index" class="nav-logo">
  <!-- <img v-if="imageUrl !== null && imageUrl !== ''" :src="imageUrl" class="logo-image" /> -->
  <img src="img/briks_logo.png" alt="BRIKS" class="logo-image" />
  <!-- <img v-else alt="BRIKS" class="logo-image" /> -->
  <!-- <span class="logo-text"> For Demo</span> -->
</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>

                    <b-navbar-nav class="ml-lg-3 hidden-sm">
                        <b-nav-item class="active mr-25" to="/index">
                            <feather-icon size="16" icon="HomeIcon" class="mr-25" />
                            <span>Home</span>
                        </b-nav-item>
                        <b-nav-item class="mr-25" to="/explore">
                            <feather-icon size="16" icon="StarIcon" class="mr-25" />
                            <span>Featured</span>
                        </b-nav-item>
     
                        <b-nav-item class="mr-25" to="/feed">
                            <feather-icon size="16" icon="CompassIcon" class="mr-25" />
                            <span>Feed</span>
                        </b-nav-item>
                        <b-nav-item-dropdown right toggleable="hover">
                            <template #button-content>
                                <feather-icon size="16" icon="FeatherIcon" class="mr-25" />
                                <span>Create</span>
                            </template>
                            <b-dropdown-item to="/create/memes">
                                <feather-icon size="16" icon="SmileIcon" class="mr-25" />
                                Memes
                            </b-dropdown-item>
                            <b-dropdown-item to="/create/quotes">
                                <feather-icon size="16" icon="SunIcon" class="mr-25" />
                                Quotes
                            </b-dropdown-item>
                            <b-dropdown-item to="/create/images">
                                <feather-icon size="16" icon="ImageIcon" class="mr-25" />
                                Images
                            </b-dropdown-item>
         
                        </b-nav-item-dropdown>
                   
          
                        <b-nav-item class="" @click='navigateToBenefits'>
                            <feather-icon size="16" icon="HeartIcon" class="mr-25" />
                            <span>My Collection</span>
                        </b-nav-item>

                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="nav align-items-center justify-content-end ml-auto">
                        <!-- <locale /> -->
                        <!-- <notification-dropdown /> -->

                        <template v-if='isLogg'>
                             <!-- <cart-dropdown /> -->
                            <user-dropdown />
                        </template>

                        <b-button variant="link" size="md" v-b-modal.modal-login class="login-btn ml-1" v-else>Login</b-button>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
    </div>

    <!-- Login Popup -->
    <login-popup ref="loginPopup" @toggleSignupPopup="toggleSignupPopup" @toggleLoginPopup="toggleLoginPopup" />

    <!-- Signup Popup -->
    <signup-popup ref="signupPopup" @toggleLoginPopup="toggleLoginPopup" @toggleVerifyCodePopup="toggleVerifyCodePopup" />

    <!-- Forgot password popup -->
    <forgot-password-popup ref="forgotPasswordPopup" @toggleLoginPopup="toggleLoginPopup" />

    <verify-code-popup ref="verifyCodePopup" />
</div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BNavItem,
    BButton,
    BLink,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import NotificationDropdown from "./components/NotificationDropdown.vue";
import CartDropdown from "./components/CartDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

import SignupPopup from "./SignupPopup";
import LoginPopup from "./LoginPopup";
import ForgotPasswordPopup from "./ForgotPasswordPopup";
import VerifyCodePopup from './VerifyCodePopup'

export default {
    components: {
        BNavbar,
        ToastificationContent,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BNavItem,
        BButton,
        BLink,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BFormCheckbox,
        vSelect,
        BContainer,

        NotificationDropdown,
        CartDropdown,
        UserDropdown,
        Locale,

        SignupPopup,
        LoginPopup,
        ForgotPasswordPopup,
        VerifyCodePopup
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    data() {
        return {
          
            isLoading: false,
       
        };
    },
    created() {
      this.syncUserFromStorage();
    },
    computed: {
        isAuth() {
            return this.$store.state.auth.user && Object.keys(this.$store.state.auth.user).length !== 0
        },
        isLogg() {
            return this.$store.state.app.isLogged
        },
        imageUrl() {

            return this.$store.state.app.imageUrl
        }

    },

    methods: {

        navigateToBenefits() {

            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
                this.$bvModal.show("modal-login");
                return false;
            } else {
                this.$router.push(
                    '/collections'
                );
            }
        },
        checkUserLoggedIn() {
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.$store.commit("app/SET_USER", false);

            } else {
                this.$store.commit("app/SET_USER", true);
            }

        },

        toggleSignupPopup() {
            this.$refs.signupPopup.show();
        },
        toggleLoginPopup() {
            this.$refs.loginPopup.show();
        },
        toggleForgotPasswordPopup() {
            this.$refs.forgotPasswordPopup.show();
        },
        toggleVerifyCodePopup() {
            this.$refs.verifyCodePopup.show();
        },

    },
  
};
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right {
    right: 0 !important;
}

.header-navbar .navbar-container {
    padding: 0 !important;
}

.header-navbar {
    padding: 0.65rem 1rem !important;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right {
    top: 45px;
}

.c-navbar .nav-link {
    display: flex;
    align-items: center;

}
.navbar-brand{
    font-size: unset !important;
}
@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}
.nav-logo {
  display: flex;
  align-items: center;
//   padding-bottom: 5px;
  margin-bottom: 5px;
}

.logo-image {
  width: auto !important; /* Adjust width as needed */
  height: auto  /* Maintain aspect ratio */
}

.logo-text {
  margin-left: 10px; /* Adjust space between image and text */
  font-size: 15px; /* Adjust font size as needed */
  color: #333; /* Adjust text color as needed */
}

@media (min-width: 768px) {
    .navbar-toggler {
        display: none !important;
    }

    .navbar-collapse {
        //    display: block !important;
        display: flex !important;
    }
}

@media (max-width: 767.98px) {
    .header-navbar .navbar-container .show .dropdown-menu {
        float: none;
        margin-top: 0;
        margin-right: 8px;
        margin-left: 8px;
    }

    .header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
        margin-top: 0px;
    }
}

@media (max-width: 600px) {
    .collapse:not(.show) {
        display: unset !important;
    }

    .navbar-toggler {
        display: none !important;
    }

    .hidden-sm {
        display: none !important;
    }

    // .navbar-collapse {
    //   flex-basis: inherit !important;
    //   flex-grow: inherit !important;
    // }
    // .navbar-nav {
    //   flex-direction: inherit;
    // }
    // li.dropdown-notification{
    //   margin-right: 16px !important;
    // }
    // li.dropdown-cart{
    //   margin-right: 16px !important;
    // }
    .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right,
    .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right {
        top: 65px;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
    }

    .loader {
        color: #ffffff;
        /* Customize the color of the spinner */
    }
}
</style><style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
