<template>
<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
        <div class="d-sm-flex d-none user-nav">
             <p class="user-name font-weight-bolder mb-0">
                {{userName}}
            </p> 
            <!-- <span class="user-status">{{ user_plan }} plan</span> -->
        </div>
        <b-avatar size="40" :text="avatarText(userName)" :src="profileImageUrl" variant="light-primary" badge class="badge-minimal avatar-user" badge-variant="success">
            <!-- <feather-icon
          v-if="!profileImageUrl"
          icon="UserIcon"
          size="22"
        /> -->
        </b-avatar>
    </template>

    <!-- <b-dropdown-item :to="{ name: 'profile-view', query: { id: true }}" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item href="/plans" link-class="d-flex align-items-center">
    <feather-icon size="16" icon="UserPlusIcon" class="mr-50" />
    <span>Plans</span>  
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
    </b-dropdown-item>
</b-nav-item-dropdown>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-item
      :to="{ name: 'Memberships' }"
      link-class="d-flex align-items-center d-block d-md-none d-lg-none"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Memberships</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Billing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->

</template>

<script>
import axios from 'axios'
import {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue'
import {
    initialAbility
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import {
    avatarText
} from '@core/utils/filter'
import { auth } from '@/firebase';
import { signOut } from 'firebase/auth';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';



export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    data() {
        return {
            avatarText,

            userData: [],
            ipAddress: '',
            user_id: ''
        }
    },

    setup() {
        return {
            avatarText,
        }
    },

   
    computed: {
        profileImageUrl() {
            return this.$store.state.app.userData.photoURL
        },
  
        user_plan() {
            return this.$store.state.app.userData.plan
        },
        userName() {
            return this.$store.state.app.userData.userName
        },
        getUserData() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData || { userName: "User" };
    }
    },
    methods: {

      async logout() {
  try {
    // Sign out from Firebase Authentication
    await signOut(auth);

    // Remove userData from localStorage
    localStorage.removeItem("userData");

    // Clear Vuex store related to the user session
    this.$store.commit("app/SET_REMOVE_ITEMS");
    this.$store.commit("app/SET_USER", false);

    // Redirect to the home page or login page
    this.$router.push("/index");

    // Optionally, show a success message
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'You have been logged out.',
        icon: 'LogOutIcon',
        variant: 'info',
      },
    });
  } catch (error) {
    console.error('Error during logout:', error);

    // Handle any errors during sign-out
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Error during logout. Please try again.',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    });
  }
}

    },
}
</script>
